import './Header.css';
import Logo from '../Logo/Logo';

export default function Header() {
  return (
    <header className='app-header'>
      <Logo />
      <ul className='nav-links'>
        <li>
          <a href='/'>Blog</a>
        </li>
        <li>
          <a href='/quiz'>Chord Quiz</a>
        </li>
        <li>
          <a href='/guitar-notes'>Guitar Notes</a>
        </li>
      </ul>
    </header>
  );
}
