import './Footer.css';
import Logo from '../Logo/Logo';
import { MdOutlineMail } from 'react-icons/md';
import { FaYoutube } from 'react-icons/fa';
import { IoLogoVenmo } from 'react-icons/io5';

export default function Footer() {
  return (
    <footer className='app-footer'>
      <Logo />
      <div className='footer-links'>
        <ul>
          <li>
            <a
              href='https://account.venmo.com/u/meganpaffrath'
              rel='noreferrer'
              target='_blank'
            >
              <IoLogoVenmo />
              &nbsp;Make a donation?
            </a>
          </li>
          <li>
            <a
              className='footer-button'
              href='https://www.youtube.com/channel/UCfba3RepDSJLG3zNz-A42Qg'
              target='_blank'
              rel='noreferrer'
            >
              <FaYoutube />
              &nbsp;Youtube
            </a>
          </li>
          <li>
            <a
              className='footer-button'
              href='mailto:lessons@axelessons.com'
              target='_blank'
              rel='noreferrer'
            >
              <MdOutlineMail />
              &nbsp;Email Us
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
