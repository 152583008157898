import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import BlogPosts from './components/BlogPosts/BlogPosts';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ChordQuiz from './components/ChordQuiz/ChordQuiz';
import GuitarNotes from './components/GuitarNotes/GuitarNotes';
import './App.css';

const BodyContent = () => {
  let routes = useRoutes([
    { path: '/', element: <BlogPosts /> },
    { path: '/quiz', element: <ChordQuiz /> },
    { path: '/guitar-notes', element: <GuitarNotes /> },
  ]);
  return routes;
};

function App() {
  return (
    <div className='App'>
      <Header />
      <div className='body-content'>
        <Router>
          <BodyContent />
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
