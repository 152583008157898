export const BLOG_POSTS = [
  {
    date: '1/14/24',
    title: 'Pentatonic Scale in the First Position and Exercises',
    blurb: `Let's learn the penatonic scale in the first position! Learning the first position is a great way to start improvising and soloing.`,
    youtubeVideoID: 'SZt7KxNL75s',
  },
  {
    date: '1/21/24',
    title:
      'Your First Guitar Lesson  - Familiarizing Yourself With The Instrument',
    blurb: `Before diving into the music, let's familarize ourselves with the parts that make up a guitar!`,
    youtubeVideoID: 'DIYGWnBO4i0',
  },
];
