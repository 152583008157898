import React from 'react';
import { useState } from 'react';

const Quiz = (props) => {
  let strings = ['low E', 'A'];
  let notes = [
    'A',
    'A#/Bb',
    'B',
    'C',
    'C#/Db',
    'D',
    'D#/Eb',
    'E',
    'F',
    'F#/Gb',
    'G',
    'G#/Ab',
  ];
  let chordTypes = ['major', 'minor', '7'];

  let lowString = strings[Math.floor(Math.random() * strings.length)];
  let noteName = notes[Math.floor(Math.random() * notes.length)];
  let chordName = chordTypes[Math.floor(Math.random() * chordTypes.length)];
  const [string, setString] = useState(lowString);
  const [rootName, setRootName] = useState(noteName);
  const [chord, setChord] = useState(chordName);

  let randomize = () => {
    setString(strings[Math.floor(Math.random() * strings.length)]);
    setRootName(notes[Math.floor(Math.random() * notes.length)]);
    setChord(chordTypes[Math.floor(Math.random() * chordTypes.length)]);
  };

  return (
    <div className='chord-quiz'>
      <h1>Chord Quiz</h1>
      <div>
        Play the{' '}
        <strong>
          {rootName} {chord}{' '}
        </strong>{' '}
        chord with root on the <strong>{string}</strong> string.
        <br></br>
        <button onClick={() => randomize()}>randomize</button>
      </div>
    </div>
  );
};

export default Quiz;
