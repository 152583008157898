import './BlogPost.css';

export default function BlogPost({ title, date, blurb, youtubeVideoID }) {
  return (
    <div className='section'>
      <h2>{title}</h2>
      <p className='post-date'>
        <strong>Date posted:</strong> {date}
      </p>
      <p>{blurb}</p>
      <iframe
        width='560'
        height='315'
        src={`https://www.youtube.com/embed/${youtubeVideoID}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title={title}
      ></iframe>
    </div>
  );
}
