// import './Header.css';
import headstock from '../../headstock.png';
import './Logo.css';

export default function Logo() {
  return (
    <a href='/' className='app-logo'>
      <img src={headstock} className='App-logo' alt='logo' />
      &nbsp;axelessons
    </a>
  );
}
