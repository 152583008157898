import React from 'react';
// import { useState } from 'react';
import { BLOG_POSTS } from './data.js';
import BlogPost from './BlogPost/BlogPost.jsx';
import './BlogPosts.css';

const BlogPosts = () => {
  return (
    <div className='posts'>
      {BLOG_POSTS.map((post, index) => (
        <BlogPost
          key={index}
          date={post.date}
          title={post.title}
          blurb={post.blurb}
          youtubeVideoID={post.youtubeVideoID}
        />
      ))}
    </div>
  );
};

export default BlogPosts;
