import React from 'react';
// import { useState } from 'react';
import './GuitarNotes.css';

const Quiz = (props) => {
  //   let notes = [
  //     'A',
  //     'A#/Bb',
  //     'B',
  //     'C',
  //     'C#/Db',
  //     'D',
  //     'D#/Eb',
  //     'E',
  //     'F',
  //     'F#/Gb',
  //     'G',
  //     'G#/Ab',
  //   ];

  return (
    <div className='chord-quiz'>
      <h1>Guitar Notes</h1>
      <div className='frets'>
        <div className='fret fret-0'>
          <div className='f0-note low-E'>
            <div className='note-name'>
              <div className='note-name'>E</div>
            </div>
          </div>
          <div className='f0-note B'>
            <div className='note-name'>B</div>
          </div>
          <div className='f0-note G'>
            <div className='note-name'>G</div>
          </div>
          <div className='f0-note D'>
            <div className='note-name'>D</div>
          </div>
          <div className='f0-note A'>
            <div className='note-name'>A</div>
          </div>
          <div className='f0-note high-E'>
            <div className='note-name'>E</div>
          </div>
          <div className='fret-number'>0</div>
        </div>
        <div className='fret fret-1'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>F</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>C</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>G#/Ab</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>D#/Eb</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>A#/Bb</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>F</div>
          </div>
          <div className='fret-number'>1</div>
        </div>
        <div className='fret fret-2'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>F#/Gb</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>C#/Db</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>A</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>E</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>B</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>F#/Gb</div>
          </div>
          <div className='fret-number'>2</div>
        </div>
        <div className='fret fret-3'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>G</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>D</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>A#/Bb</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>F</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>C</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>G</div>
          </div>
          <div className='fret-number'>3</div>
        </div>
        <div className='fret fret-4'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>G#/Ab</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>D#/Eb</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>B</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>F#/Gb</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>C#/Db</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>G#/Ab</div>
          </div>
          <div className='fret-number'>4</div>
        </div>
        <div className='fret fret-5'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>A</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>E</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>C</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>G</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>D</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>A</div>
          </div>
          <div className='fret-number'>5</div>
        </div>
        <div className='fret fret-6'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>A#/Bb</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>F</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>C#/Db</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>G#/Ab</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>D#/Eb</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>A#/Bb</div>
          </div>
          <div className='fret-number'>6</div>
        </div>
        <div className='fret fret-7'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>B</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>F#/Gb</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>D</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>A</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>E</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>B</div>
          </div>
          <div className='fret-number'>7</div>
        </div>
        <div className='fret fret-8'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>C</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>G</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>D#/Eb</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>A#/Bb</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>F</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>C</div>
          </div>
          <div className='fret-number'>8</div>
        </div>
        <div className='fret fret-9'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>C#/Db</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>G#/Ab</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>E</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>B</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>F#/Gb</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>C#/Db</div>
          </div>
          <div className='fret-number'>9</div>
        </div>
        <div className='fret fret-10'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>D</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>A</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>F</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>C</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>G</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>D</div>
          </div>
          <div className='fret-number'>10</div>
        </div>
        <div className='fret fret-11'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>D#/Eb</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>A#/Bb</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>F#/Gb</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>C#/Db</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>G#/Ab</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>D#/Eb</div>
          </div>
          <div className='fret-number'>11</div>
        </div>
        <div className='fret fret-11'>
          <div className='note low-E'>
            <div className='v-line'></div>
            <div className='note-name'>E</div>
          </div>
          <div className='note B'>
            <div className='v-line'></div>
            <div className='note-name'>B</div>
          </div>
          <div className='note G'>
            <div className='v-line'></div>
            <div className='note-name'>G</div>
          </div>
          <div className='note D'>
            <div className='v-line'></div>
            <div className='note-name'>D</div>
          </div>
          <div className='note A'>
            <div className='v-line'></div>
            <div className='note-name'>A</div>
          </div>
          <div className='note high-E'>
            <div className='v-line'></div>
            <div className='note-name'>E</div>
          </div>
          <div className='fret-number'>12</div>
        </div>
      </div>
      <div className='section'>
        <h2>Memorization Exercise</h2>
        <p>
          A great way to get started on guitar is by learning all of the notes
          on the fretboard. A great simple exercise is to go through each note
          A, B, C, D, E, F, G (in order) and locate each note on each string
          starting on the low E, going up to the high E, then back again to the
          low E. As you get good at this, start using a metronome!
        </p>
        <p>
          Another great thing to learn is sections of the neck. Try:
          <ul>
            <li>Learning all the notes on the open strings</li>
            <li>Learning all the notes on the low E string</li>
            <li>Learning all the notes on the A string</li>
            <li>Learning all the notes on fret 5</li>
            <li>Learning all the notes on fret 10</li>
            <li>Learning all the notes on fret 12</li>
            <li>Learning all the notes on fret 3</li>
            <li>Learning all the notes on fret 7</li>
            <li>Learning all the notes on fret 8</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Quiz;
